import { keyframes } from "@emotion/react";
import chatbotImg from "../images/chatbot.png";

const prod = "https://us-central1-genbotnoetic.cloudfunctions.net/chatbot";
const dev = "http://localhost:4000";

export const URL =
  window.location.hostname.split(":")[0] === "localhost" ||
  window.location.hostname.includes("192")
    ? dev
    : prod;

const animationKeyframes = keyframes`
  0% { transform: scale(1) rotate(0); border-radius: 10%; }
  25% { transform: scale(1.2) rotate(0); border-radius: 12%; }
  50% { transform: scale(1.2) border-radius: 20%; }
  75% { transform: scale(1)  border-radius: 30%; }
  100% { transform: scale(1) rotate(0); border-radius: 40%; }
`;

export const customAnimation = `${animationKeyframes} 2s ease-in-out infinite`;

export const initialMessage = {
  from: "Computer",
  text: "Hi there ! Please select from below options",
  type: "text",
  isInitialMessage: true,
  quickReplies: [
    "Our Services",
    "Estimates / Quotes",
    "Our Company",
    "Resources",
    "Contact Us",
    "Location",
    "Service Requests",
  ],
};

export const chatbotImgUrl = chatbotImg;
// "https://firebasestorage.googleapis.com/v0/b/noetic-digital/o/chatbot-icon%2FSurveyBotWEB.png?alt=media&token=302ac53c-b6c0-4c7f-a340-e35a71e82a8b";

export const surveys = {
  livingConceptSurveys: "livingConceptSurveys",
  feedbackSurvey: "feedbackSurvey",
};