import "./App.css";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter } from "react-router-dom";
import Routing from "./Routing";

function App() {

  return (
    <BrowserRouter>
      <ChakraProvider>
        <Routing />
      </ChakraProvider>
    </BrowserRouter>
  );
}

export default App;
