import React, { useEffect } from "react";
import Chatbot from "../components/Chatbot";
import Layout from "../Layout";

function Chat({ props }) {
  const { screenerQuestions, questions, name } = props || {};
  questions[0]["show_description"] = true;

  return (
    <Layout name={name}>
      <Chatbot
        screenerQuestions={screenerQuestions}
        questions={questions}
        data={props}
      />
    </Layout>
  );
}

export default Chat;
