import React, { useEffect, useState } from "react";
import { Avatar, Flex, Text, Image } from "@chakra-ui/react";
import { chatbotImgUrl } from "../utils/constant";

const ChatbotImageAndDesc = ({ image, description }) => {
  const [showDescription, setShowDescription] = useState(false);
  const [showImage, setShowImage] = useState(false);

  useEffect(() => {
    setShowDescription(true);
    setShowImage(true);
  }, []);

  // Check if description is null or an empty array
  if (image === null && (description === null || Array.isArray(description))) {
    return null;
  }
  return (
    <Flex>
      <Flex display={"block"} align={"center"}>
        {image && showImage && (
          <Flex w={"387px"} m={"0 -69px"}>
            <Avatar
              name="Computer"
              src={chatbotImgUrl}
              bg="blue.300"
              mr={5}
            ></Avatar>
            <Flex
              bg="white"
              minW="100px"
              maxW="350px"
              my="1"
              p="3"
              borderRadius={"16px"}
            >
              <Image src={image} />
            </Flex>
          </Flex>
        )}
        {description && !Array.isArray(description) && showDescription && (
          <Flex w={"387px"} m={"0 -69px"}>
            <Avatar
              name="Computer"
              src={chatbotImgUrl}
              bg="blue.300"
              mr={5}
            ></Avatar>
            <Flex
              bg="white"
              minW="100px"
              maxW="350px"
              my="1"
              p="3"
              borderRadius={"16px"}
            >
              <Text style={{ whiteSpace: "pre-line" }}>{description}</Text>
            </Flex>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default ChatbotImageAndDesc;
