import axios from "axios";
import React, { useEffect, useState } from "react";
import { Routes, Route, useSearchParams } from "react-router-dom";
import Chat from "./Pages/Chat";
import PageNotFound from "./Pages/PageNotFound";
import { URL } from "./utils/constant";
function Routing() {
  // eslint-disable-next-line
  const [searchParams, _] = useSearchParams();
  const surveyId = searchParams.get("surveyId");
  const clientId = searchParams.get("clientId");
  const surveyName = searchParams.get("survey");
  const lang = searchParams.get("lang");
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);

  const getDataApi = async () => {
    try {
      const resData = await axios({
        method: "get",
        url: `${URL}/get-questions`,
        params: {
          clientId,
          surveyId,
          surveyName,
          lang,
        },
      });
      if (Object.values(resData?.data?.response?.questions || [])) {
        setData(resData?.data?.response ?? []);
      } else {
        setData([]);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };
  useEffect(() => {
    getDataApi();
  }, [clientId, surveyId, lang, surveyName]);

  const shouldRouteToChat =
    surveyId && clientId && !loading && !!data?.questions?.length;
  const page = shouldRouteToChat ? (
    <Chat
      props={{
        ...data,
        clientId: clientId,
        surveyId: surveyId,
      }}
    />
  ) : (
    <PageNotFound loading={loading} />
  );
  return (
    <Routes>
      <Route path="*" element={page} />
    </Routes>
  );
}

export default Routing;
