import { Avatar, Badge, Box, Flex, Image, Link, Text } from "@chakra-ui/react";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { URL, chatbotImgUrl, surveys } from "../utils/constant";
import ChatbotImageAndDesc from "./chatbotImageAndDesc";
import { MessageRenderer } from "./messageRenderer";
const Messages = ({
  messages,
  setMessages,
  answers,
  setAnswerNo,
  answerNo,
  msgNo,
  setMsgNo,
  setInputMessage,
  formatedQuestions,
  multiselect,
  updateSurvey,
  setMultiselect,
  data,
  setGender,
  isAgeValid,
  genderNo,
  userAge,
  sessionId,
  gender,
  setUserAge,
  optionData,
  isNotAgreedFunction,
  setRepliedMultiselect,
  repliedMultiselect,
  inputMessage,
  setSendButtonEnable,
  disable,
  redirectSurvey,
}) => {
  const [showQuestions, setShowQuestions] = useState(false);
  const [noneOfAboveSelected, setNoneOfAboveSelected] = useState(false);
  const [showContent, setShowContent] = useState(true);
  const [isAgeAnswerGiven, setIsAgeAnswerGiven] = useState(false);
  const [isGenderAnswerGiven, setIsGenderAnswerGiven] = useState(false);
  const [searchParams] = useSearchParams();
  const surveyName = searchParams.get("survey");
  const transactionId = searchParams.get("transaction_id");
  const agePerams = searchParams.get("age");
  const genderPerams = searchParams.get("gender");
  let isLastQuestion = answers.length + 1 >= formatedQuestions.length;
  const surveyId = searchParams.get("surveyId");
  const already_Completed = atob(localStorage.getItem("identity")) === surveyId;
  const quota_exceeds =
    data?.compeletesRequired !== 0 &&
    data?.compeletesRequired === data?.noOfRespondents;
  const lang = searchParams.get("lang");
  const screenerOptionsToContinue = [
    "Strongly Agree",
    "Somewhat agree",
    "非常にそう思う",
    "そう思う",
  ];
  useEffect(() => {
    setTimeout(() => {
      
      if ( !already_Completed && (!transactionId || !quota_exceeds)) {
        setShowQuestions(true);
      }
      if (already_Completed) {
        redirectSurvey(30);
      } else if (quota_exceeds) {
        redirectSurvey(17);
      }
    }, 3000);
  }, []);

  // always scroll to bottom
  const AlwaysScrollToBottom = () => {
    const elementRef = useRef();
    useEffect(() => elementRef.current.scrollIntoView());
    return <div ref={elementRef} />;
  };
  const multiselectQuestion =
    lang === "jap"
      ? "この商品が発売されるとしたらどのくらいの確率でしょうか"
      : "If this product becomes available in the future at a fair price you can afford and with adequate support to help you to feel comfortable with using it, how likely would it be ";
  function updateGenderCount(selectedGender, selectedAge) {
    if (
      selectedGender === "male" ||
      selectedGender === "female" ||
      selectedGender === "男性" ||
      selectedGender === "女性"
    ) {
      const range = Object.keys(genderNo[0]).findIndex(
        (key) => key === selectedAge.toString().replace(/\s/g, "")
      );
      if (range !== -1) {
        const rangeKey = Object.keys(genderNo[0])[range];
        const maleCount = genderNo[0][rangeKey].male;
        const femaleCount = genderNo[0][rangeKey].female;
        if (
          (selectedGender === "male" && maleCount >= 7) ||
          (selectedGender === "female" && femaleCount >= 7)
        ) {
          return false;
        }
        genderNo[0][rangeKey][selectedGender]++;
        return true;
      } else {
        return false;
      }
    }
  }

  const SaveAnswers = (answer) => {
    axios
      .post(`${URL}/save-answers`, {
        ...answer,
        surveyId,
        surveyName,
        isLastQuestion,
      })
      .then((res) => {})
      .catch((err) => err);
  };
  const SaveAnswersToSheet = (answer, answerNumber) => {
    axios({
      method: "post",
      url: `${URL}/save-answer-to-spreadSheet`,
      params: {
        sheetId: data?.sheetId,
        tabId: data?.tabId,
        answer: answer,
        answerNo: `Ans${answerNumber || answerNo}`,
        sessionId: sessionId,
      },
    })
      .then(function (response) {
        return response;
      })
      .catch((err) => {
        return err;
      });
    setAnswerNo((prev) => Number(prev) + 1);
  };
  const handleSendMessage = ({ value, notagreed, isSubLast }) => {
    if (notagreed) {
      setMessages((old) => [...old, { from: "me", text: value }]);
      isNotAgreedFunction();
      SaveAnswersToSheet(value);
      return setMessages((old) => [
        ...old,
        {
          from: "Computer",
          text: isSubLast
            ? lang === "eng"
              ? "You are not eligible to fill this survey"
              : "あなたにはこのアンケートに回答する資格がありません"
            : data?.goodbyeMsg,
        },
      ]);
    }

    // extra validation if disable button is removed
    if (answers.length >= formatedQuestions.length && notagreed) {
      return setMessages((old) => [
        ...old,
        { from: "Computer", text: data?.goodbyeMsg },
      ]);
    }

    if (!value?.trim().length) {
      return;
    }

    if (!multiselect?.length) {
      setMessages((old) => [
        ...old,
        {
          from: "me",
          text: value,
          isLastScreenerQuestion:
            formatedQuestions[answers.length].isLastScreenerQuestion,
        },
      ]);
    }
    if (messages.length === 7) {
      setGender(value.toLowerCase());
    }
    SaveAnswers({
      answers: [
        {
          questionId: formatedQuestions[answers.length]?.id,
          answer: value,
          qNo: answers.length + 1,
        },
      ],
    });
    SaveAnswersToSheet(value);
    if (!notagreed) {
      if (value === "Less than 35") {
        return setMessages((old) => [
          ...old,
          {
            from: "Computer",
            text:
              lang === "eng"
                ? "You are not eligible to fill this survey"
                : "あなたにはこのアンケートに回答する資格がありません",
          },
        ]);
      }
      setMsgNo((prev) => prev + 1);
      if (!!multiselect && multiselect?.length) {
        if (!!value.trim().length && multiselect?.length) {
          setMessages((old) => [...old, { from: "me1", text: value }]);
          setInputMessage("");
        }
        if (multiselect?.length > 1) {
          setTimeout(() => {
            setMessages((old) => [
              ...old,
              {
                from: "Computer",
                text: `${multiselectQuestion}${multiselect[
                  repliedMultiselect
                ].toLowerCase()}?`,
                question_type: "quick_replies",
                options: optionData(),
              },
            ]);
          }, 1500);
          if (multiselect?.length - 1 > repliedMultiselect) {
            setRepliedMultiselect(repliedMultiselect);
          } else {
            setMultiselect(false);
          }
          setSendButtonEnable(false);
        } else {
          setMultiselect(false);
        }
      }

      if (
        surveys?.[surveyName] &&
        answers.length < 1 &&
        formatedQuestions[0]?.age &&
        !isAgeValid(value)
      ) {
        setInputMessage("");
        isNotAgreedFunction();

        return setMessages((old) => [
          ...old,
          {
            from: "Computer",
            text:
              lang === "eng"
                ? "You are not eligible to fill this survey"
                : "あなたにはこのアンケートに回答する資格がありません",
          },
        ]);
      }
      if (answers.length < 2) {
        setUserAge(value);
      }
      // automaticly send computer message after 1 minute interval
      const removeQuestionsAndSaveAnswer = (
        param,
        questionText,
        answer,
        answerNumber,
        alreadyAnswered
      ) => {
        if (param && !alreadyAnswered && transactionId) {
          const question = formatedQuestions.find(
            (q) => q.question === questionText
          );
          formatedQuestions = formatedQuestions.filter(
            (q) => q.question !== questionText
          );
          SaveAnswers({
            answers: [
              {
                questionId: question?.id,
                answer,
                qNo: answerNumber,
              },
            ],
          });
          SaveAnswersToSheet(answer, answerNumber);
          if (questionText === "What is your age?") {
            setUserAge(answer);
            setIsAgeAnswerGiven(true);
          }
          if (questionText === "What is your gender?") {
            setIsGenderAnswerGiven(true);
          }
        }
      };
      removeQuestionsAndSaveAnswer(
        agePerams,
        "What is your age?",
        agePerams,
        2,
        isAgeAnswerGiven
      );
      removeQuestionsAndSaveAnswer(
        genderPerams,
        "What is your gender?",
        genderPerams == "111" ? "Male" : "Female",
        3,
        isGenderAnswerGiven
      );

      setTimeout(() => {
        if (agePerams && transactionId) {
          formatedQuestions = formatedQuestions.filter(
            (q) => q.question !== "What is your age?"
          );
        }
        if (genderPerams && transactionId) {
          formatedQuestions = formatedQuestions.filter(
            (q) => q.question !== "What is your gender?"
          );
        }
        let tempAnswerLength =
          answers.length >= 0 ? answers.length + 1 : answers?.length;
        if (
          answers.length < formatedQuestions.length - 1 &&
          !multiselect &&
          (!formatedQuestions[tempAnswerLength]?.nestedQuestion ||
            typeof formatedQuestions[tempAnswerLength]?.nestedQuestion !=
              "object")
        ) {
          let nextQuestion = formatedQuestions[tempAnswerLength];
          nextQuestion = nextQuestion?.question?.includes("{{ans_1}}")
            ? {
                ...nextQuestion,
                question: nextQuestion?.question?.replaceAll(
                  "{{ans_1}}",
                  value
                ),
              }
            : nextQuestion;
          if (
            answers.length === 3 &&
            (value === "Male" ||
              value === "Female" ||
              value === "男性" ||
              value === "女性") &&
            !updateGenderCount(
              value === "男性"
                ? "male"
                : value === "女性"
                ? "female"
                : value.toLowerCase(),
              userAge
            )
          ) {
            isNotAgreedFunction();
            return setMessages((old) => [
              ...old,
              {
                from: "Computer",
                text:
                  lang === "eng"
                    ? "Number of submission form has been exceeded"
                    : "送信フォームの数が超過しました",
              },
            ]);
          }
          setMessages((old) => [...old, nextQuestion]);
        }
        // checks for last answer, answers array starts from 0
        if (answers.length === formatedQuestions.length - 1) {
          setTimeout(() => {
            setMessages((old) => [
              ...old,
              { from: "Computer", image: true },
              { from: "Computer", text: data?.description, description: true },
              { from: "Computer", text: data?.goodbyeMsg },
              data?.goodbyeURL && {
                from: "Computer",
                text: data?.goodbyeURL,
                url: true,
              },
            ]);
          }, 1500);
          localStorage.setItem("identity", btoa(surveyId));
          redirectSurvey(21);
        }
      }, 1500);
    }
  };

  const badgeChangeHandler = (
    value,
    isSubLast,
    isLastScreenerQuestion,
    shouldCheckCondition
  ) => {
    if (isSubLast && !screenerOptionsToContinue.includes(value)) {
      handleSendMessage({ value, notagreed: true, isSubLast: true });
    } else if (
      (isLastScreenerQuestion && !screenerOptionsToContinue.includes(value)) ||
      (!!shouldCheckCondition && value !== "Yes" && value !== "はい")
    ) {
      handleSendMessage({ value, notagreed: true });
    } else {
      handleSendMessage({ value, notagreed: false, isSubLast: false });
    }
    if (isLastScreenerQuestion && screenerOptionsToContinue.includes(value)) {
      updateSurvey();
    }
  };
  const isAlreadySelected = (itemName) =>
    !!multiselect?.length && multiselect?.some((item) => item === itemName);

  const multiselectHandler = (value, isLastQuestion) => {
    if (
      noneOfAboveSelected &&
      value !== "None of the above is applicable" &&
      value !== "専門家に鑑定してもらうには"
    ) {
      return;
    }
    if (
      value === "None of the above is applicable" ||
      value === "専門家に鑑定してもらうには"
    ) {
      setSendButtonEnable(true);
      setMultiselect(["None of the above is applicable"]);
      setNoneOfAboveSelected(!noneOfAboveSelected);
    }
    let temp = multiselect?.length && !isLastQuestion ? [...multiselect] : [];
    temp.push(value);
    if (isAlreadySelected(value)) {
      setSendButtonEnable(true);
      setMultiselect((prev) => prev?.filter((val) => val !== value));
    } else {
      setSendButtonEnable(true);
      setMultiselect(temp);
    }
  };

  useEffect(() => {
    setShowContent(messages.length !== 11);
    if (messages.length === 11) {
      setTimeout(function () {
        setShowContent(true);
      }, 5000);
    }
  }, [messages.length]);

  return (
    <>
      {!!transactionId && already_Completed && (
        <MessageRenderer
          description={"Sorry u have already completed this survey"}
        />
      )}
      {!!transactionId && !already_Completed && quota_exceeds && (
        <MessageRenderer
          description={"Sorry this survey quota has been exceeds"}
        />
      )}
      {data?.welcomeMsg && !already_Completed && !quota_exceeds && (
        <MessageRenderer description={data?.welcomeMsg} />
      )}
      <Flex
        w="100%"
        flexDirection="column"
        p="3"
        mt="5"
        display={"flex"}
        align={"center"}
        justifyContent={"center"}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          maxWidth: "410px",
        }}
      >
        {showQuestions &&
          messages?.length &&
          messages?.map((item, index) => {

            if (item?.from === "Computer") {
              let youtubeId = "";
              let url = item?.url;
              let quickReplies = [];
              let multiselect = [];
              if (String(item?.question_type)?.toLowerCase() === "yes_no") {
                quickReplies = ["Yes", "No"];
              } else if (
                String(item?.question_type)?.toLowerCase() === "quick_replies"
              ) {
                quickReplies = item?.options;
              } else if (
                String(item?.question_type)?.toLowerCase() === "multiselect"
              ) {
                multiselect = item?.options;
              }
              if (String(item?.question_type)?.toLowerCase() === "video") {
                youtubeId = url.split("v=")[1]?.includes("&")
                  ? url.split("v=")[1]?.split("&")[0]
                  : url.split("v=")[1];
              }
              return (
                <Flex
                  key={index}
                  w="100%"
                  mt={"5px"}
                  sx={{ alignItems: "flex-end" }}
                >
                  <Avatar
                    name="Computer"
                    src={chatbotImgUrl}
                    bg="blue.300"
                    mr={5}
                  />
                  <Box>
                    {item?.show_description && (
                      <>
                        <ChatbotImageAndDesc description={data?.description} />
                        <ChatbotImageAndDesc image={data?.image} />
                      </>
                    )}

                    {(item?.question || item?.text || item?.image) &&
                      (showContent || (!showContent && index !== 10)) && (
                        <Flex
                          bg={"white"}
                          minW="100px"
                          maxW="350px"
                          my="1"
                          p="3"
                          borderRadius={"16px"}
                        >
                          <Flex display={"block"} align={"center"}>
                            <Box>
                              {item?.image && (
                                <Box>
                                  <Flex
                                    bg="white"
                                    minW="100px"
                                    maxW="350px"
                                    my="1"
                                    p="3"
                                    borderRadius={"16px"}
                                  >
                                    <Image src={data?.image} />
                                  </Flex>
                                </Box>
                              )}

                              {item?.question ||
                                (item?.text && (
                                  <Text
                                    sx={{
                                      wordBreak: item?.url
                                        ? "break-all"
                                        : undefined,
                                      whiteSpace: item?.description
                                        ? "pre-line"
                                        : undefined,
                                    }}
                                  >
                                    {item?.url ? (
                                      <Link
                                        color="#8756ff"
                                        href={item?.text}
                                        isExternal
                                      >
                                        {item?.text}
                                      </Link>
                                    ) : (
                                      item?.question ?? item?.text
                                    )}
                                  </Text>
                                ))}

                              {youtubeId && (
                                <iframe
                                  width="330"
                                  height="250"
                                  sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
                                  style={{ borderRadius: 10, marginTop: 10 }}
                                  src={`https://youtube.com/embed/${youtubeId}?autoplay=0`}
                                  title="YouTube video player"
                                  frameborder="0"
                                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                  allowfullscreen
                                ></iframe>
                              )}
                            </Box>
                          </Flex>
                        </Flex>
                      )}
                    {item?.nestedQuestion && (
                      <Flex
                        bg="white"
                        minW="100px"
                        maxW="350px"
                        mb={1}
                        p="3"
                        borderRadius={"16px"}
                      >
                        <Text sx={{ wordBreak: item?.url && "break-all" }}>
                          {item.nestedQuestion}
                        </Text>
                      </Flex>
                    )}

                    {/* {Chatbot Quick replies} */}
                    {(!!item?.quickReplies?.length ||
                      (!!quickReplies?.length &&
                        (showContent || (!showContent && index !== 10)))) && (
                      <Box>
                        {(item?.quickReplies || quickReplies)?.map(
                          (value, index) => (
                            <Badge
                              key={index}
                              cursor={"pointer"}
                              mr={2}
                              mb={2}
                              background={"white"}
                              padding={"8px"}
                              borderRadius={"5px"}
                              onClick={() =>
                                badgeChangeHandler(
                                  value,
                                  item?.isSubLast,
                                  item?.isLastScreenerQuestion,
                                  item.shouldCheckCondition
                                )
                              }
                            >
                              {value}
                            </Badge>
                          )
                        )}
                      </Box>
                    )}

                    {(!!item?.multiselect?.length || !!multiselect?.length) && (
                      <Box>
                        {(item?.multiselect || multiselect)?.map(
                          (value, index) => {
                            return (
                              <Badge
                                key={index}
                                cursor={"pointer"}
                                mr={2}
                                mb={2}
                                border={
                                  isAlreadySelected(value)
                                    ? "1px solid green"
                                    : ""
                                }
                                style={
                                  noneOfAboveSelected &&
                                  value !== "None of the above is applicable"
                                    ? { background: "grey" }
                                    : { background: "white" }
                                }
                                padding={"8px"}
                                borderRadius={"5px"}
                                onClick={() =>
                                  multiselectHandler(value, item.isLastQuestion)
                                }
                              >
                                {value}
                              </Badge>
                            );
                          }
                        )}
                      </Box>
                    )}
                  </Box>
                </Flex>
              );
            } else {
              return (
                <Box key={index} w="100%" justify="flex-end">
                  <Flex key={index} w="100%" justify="flex-end">
                    <Flex
                      bg="#8756ff"
                      borderRadius={"5px"}
                      color="white"
                      minW="100px"
                      maxW="350px"
                      my="1"
                      p="3"
                    >
                      {/* {Audio is added in case of future feature of audio player} */}
                      {item?.type === "inputAudio" ? (
                        <audio src={item?.inputAudio?.blobURL} controls />
                      ) : (
                        // {Text messages from opposit user}
                        <Text sx={{ wordBreak: "break-all" }}>
                          {item?.text}
                        </Text>
                      )}
                    </Flex>
                  </Flex>
                  {item?.isLastScreenerQuestion && (
                    <MessageRenderer
                      image={data?.image}
                      description={data?.description}
                    />
                  )}
                </Box>
              );
            }
          })}

        <AlwaysScrollToBottom />
      </Flex>
    </>
  );
};
export default Messages;
